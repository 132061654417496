<script setup>
  import { createWorker, PSM, OEM } from 'tesseract.js'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const imageSrc = ref('')
  const recognizedText = ref('')

  const data = reactive({
    loading: false,
    imageSrc: '',
    recognizedText: ''
  })

  const onFileChange = (event) => {
    const file = event.target.files[0]
    data.imageSrc = URL.createObjectURL(file)
  }

  const recognizeText = async () => {
    if (data.loading) return
    data.loading = true
    try {
      const worker = await createWorker()
      await worker.load()
      await worker.loadLanguage('eng')
      await worker.initialize('eng')
      // await worker.setParameters({
      //   tessedit_char_whitelist: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ#',
      //   tessedit_pageseg_mode: PSM.SINGLE_BLOCK
      // })
      const {
        data: { text }
      } = await worker.recognize(data.imageSrc)
      data.recognizedText = text
      await worker.terminate()
      data.loading = false
    } catch (error) {
      console.error(error)
      data.loading = false
    }
  }
</script>

<template>
  <div class="ocr-box">
    <input type="file" @change="onFileChange" />
    <el-button type="primary" @click="recognizeText">识别文字</el-button>
    <div class="orc-text mt10" v-loading="data.loading">{{ data.recognizedText }}</div>
  </div>
</template>

<style lang="less" scoped>
  .ocr-box {
    width: 600px;
    min-height: 400px;
    padding: 40px;
    .orc-text {
      width: 400px;
      min-height: 200px;
      border-radius: 10px;
      border: 1px solid #999;
      word-wrap: break-word;
      white-space: pre-wrap;
      padding: 10px;
    }
  }
</style>
